<template>
  <div class="job-board">
    <div class="job-board-detail-component">
      <div class="container">
        <div class="row logo-summary mt-3">
          <div
            class="
              col-lg-4 col-sm-12 col-md-12
              d-flex
              justify-content-center
              align-items-center
              p-0
            "
          >
            <div class="d-flex flex-column align-items-center">
              <div class="text-center" style="width: 40%; margin-top: 16px">
                <img
                  v-if="fileSource.logo"
                  :src="fileSource.logo"
                  class="rounded-circle w-100 h-100"
                  style="w-100 h-100"
                />
                <img
                  v-else
                  src="../../../public/img/hospital.png"
                  class="rounded-circle"
                  style=" w-100 h-100"
                />
              </div>
              <div class="my-3">
                <h6 class="text-primary text-center">
                  {{ organisation }}
                </h6>
                <div class="location text-primary">
                  <p>
                    <i class="fas fa fa-map-marker-alt"></i>&nbsp;
                    <span style="color: #00000099">{{ location }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-sm-12 col-md-12">
            <div class="title">
              <p class="text-left">
                {{ title }}
              </p>
            </div>
            <div class="d-flex">
              <CRow class="profileView">
              <CCol lg="6">  
                <label class="text-gray">Candidate Type</label>
              </CCol>
              <CCol lg="6">
                <span class="text-black">{{ candidateType }}</span>
              </CCol>
              
              <CCol lg="6">  
                <label class="text-gray">Candidate Level</label>
              </CCol>
              <CCol lg="6">
                <span class="text-black">{{ candidateLevel }}</span>
              </CCol>

              <CCol lg="6">  
                <label class="text-gray">Job Reference</label>
              </CCol>
              <CCol lg="6">
                <span class="text-black">{{ jobReference }}</span>
              </CCol>

                <CCol lg="6">  
                <label class="text-gray">Contract</label>
              </CCol>
              <CCol lg="6">
                <span class="text-black">{{ contract }}</span>
              </CCol>

              <CCol lg="6">  
                <label class="text-gray">Date Posted</label>
              </CCol>
              <CCol lg="6">
                <span class="text-black">{{ date_created }}</span>
              </CCol>

              <CCol lg="6">  
                <label class="text-gray">Expires on</label>
              </CCol>
              <CCol lg="6">
                <span class="text-black">{{ expiryDate }}</span>
              </CCol>
              </CRow> 
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="detail-group">
                <div class="action-group mt-2">
                  <button
                    type="button"
                    class="btn btn-primary mb-3 mr-3"
                    @click="editPublicJob"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary mb-3 ml-3"
                    @click="deletePublicJob"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div class="pr-2 pl-3 mt-3 job-description-content" v-html="jobDescription"></div>
    </div>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { BASE_URL } from "@/service_urls";
import Modal from "@/components/reusable/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      urlFullPath: "",
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Job",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  created() {
    const {
      fullPath,
      params: { id },
    } = this.$route;
    this.urlFullPath = fullPath;
  },
  computed: {
    ...mapGetters([
      "getSelectedPublicJobIssuerBoard",
      "getApplicationStatus",
      "getSelectedPublicJobIssuerBoardAdvert",
    ]),
    jobStatus() {
      return this.getSelectedPublicJobIssuerBoard?.job_status?.status || "";
    },
    candidateType() {
      return (
        this.getSelectedPublicJobIssuerBoard?.candidate_type?.candidate_type ||
        "--"
      );
    },
    candidateLevel() {
      return (
        this.getSelectedPublicJobIssuerBoard?.level_names?.join(", ") || "--"
      );
    },
    featured() {
      return this.getSelectedPublicJobIssuerBoard?.featured ? "Yes" : "No";
    },
    organisation() {
      if (!this.isOwnJob) {
        return this.getSelectedPublicJobIssuerBoard?.organisation_name || "";
      }
      return this.getSelectedPublicJobIssuerBoard?.organisation?.name || "--";
    },
    jobDescription() {
      return (
        this.getSelectedPublicJobIssuerBoardAdvert?.advert_description || ""
      );
    },
    location() {
      return this.getSelectedPublicJobIssuerBoard?.location?.location || "--";
    },
    jobReference() {
      return `${this.getSelectedPublicJobIssuerBoard?.job_id}` || "--";
    },
    title() {
      return this.getSelectedPublicJobIssuerBoard?.job_title || "";
    },
    status() {
      return this.getSelectedPublicJobIssuerBoard?.status?.status || "";
    },
    appliedOn() {
      let appliedOn = this.getSelectedPublicJobIssuerBoard?.created_on || "";
      return moment(appliedOn).format("YYYY-MM-DD");
    },
    contract() {
      if (this.getSelectedPublicJobIssuerBoard) {
        const { permanent_work, temporary_work } =
          this.getSelectedPublicJobIssuerBoard;
        if (permanent_work && temporary_work) return "Permanent;Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
        else return "--";
      } else {
        return "--";
      }
    },
    expiryDate() {
      let expiry_date = this.getSelectedPublicJobIssuerBoard?.sharing_end_date ?
        this.getSelectedPublicJobIssuerBoard?.sharing_end_date
        : this.getSelectedPublicJobIssuerBoard?.expiry_review_date
      return (
        moment(expiry_date).format(
          "DD-MM-YYYY"
        ) || "NA"
      );
    },
    date_created() {
      let date_posted = this.getSelectedPublicJobIssuerBoard?.sharing_start_date ?
        this.getSelectedPublicJobIssuerBoard?.sharing_start_date
        : this.getSelectedPublicJobIssuerBoard?.created_on
      return (
        moment(date_posted).format(
          "DD-MM-YYYY"
        ) || "NA"
      );
    },
    url() {
      return window?.location?.href;
    },
    hospital() {
      return this.getSelectedPublicJobIssuerBoard?.hospital || "--";
    },
    days_ago() {
      let created = moment(this.getSelectedPublicJobIssuerBoard?.created_on);
      let today = moment();
      return today.diff(created, "days") !== 0
        ? today.diff(created, "days")
        : "New";
    },
    fileSource() {
      let job = this.getSelectedPublicJobIssuerBoard;
      return {
        logo: job?.organisation?.logo_url
          ? window.location.origin + job?.organisation?.logo_url
          : "",
        image: job?.organisation?.default_job_banner_url
          ? window.location.origin + job?.organisation?.default_job_banner_url
          : "",
      };
    },
    favouriteID() {
      return this.getSelectedPublicJobIssuerBoard?.ids?.favourite_id || null;
    },
    appliedID() {
      return this.getSelectedPublicJobIssuerBoard?.ids?.applied_id || null;
    },
    isActiveJob() {
      let active_job_status = [29];
      return active_job_status.includes(
        this.getSelectedPublicJobIssuerBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    isPendingJob() {
      let pending_job_status = [28];
      return pending_job_status.includes(
        this.getSelectedPublicJobIssuerBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    isDraftOrPending() {
      let draft_or_pending_job_status = [27, 28];
      return draft_or_pending_job_status.includes(
        this.getSelectedPublicJobIssuerBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    isInActiveJob() {
      let inActive_job_status = [30];
      return inActive_job_status.includes(
        this.getSelectedPublicJobIssuerBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    is_rejected() {
      return (
        this.getSelectedPublicJobIssuerBoard?.is_accepted === false || false
      );
    },
    isOwnJob() {
      if (this.getSelectedPublicJobIssuerBoard?.is_own_job === null) {
        return true;
      }
      return this.getSelectedPublicJobIssuerBoard?.is_own_job;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "softDeletePublicSharedJob",
    ]),
    numberWithCommas(number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    editPublicJob() {
      this.$router.push({
        path: `/edit/public/job/${this.getSelectedPublicJobIssuerBoard?.job_id}`,
      });
    },
    deletePublicJob() {
      this.deleteConfirmModal.modalContent = `Are you sure you want to delete the "${this.title}" Job?`;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        this.softDeletePublicSharedJob(this.getSelectedPublicJobIssuerBoard?.job_uuid)
      }
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}
.profileView {  
    margin: 0px;
    padding: 3px;
    font-size: 12px;
    margin-bottom: 5px;
    
    .text-gray {
      font-size: 11px;
    } 
    div{
      border-bottom: solid 1px #EEE;
    }
  } 

  .text-gray {
    color: #6f6f6f;
    padding-right: 5px;
  } 
</style>
