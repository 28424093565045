<template>
  <div class="job-issuer-home">
    <LoadingOverlay v-if="isPublicJobIssuerBoardFetching" />
    <CRow class="mb-4">
      <CCol md="10"></CCol>
      <CCol>
        <div class="d-flex flex-column">
          <CButton
            @click="navigateToAddPublicJob"
            class="btn-primary"
            title="Create Job"
            >Create Job</CButton
          >
        </div>
      </CCol>
    </CRow>
    <div class="d-flex flex-row">
      <div 
        class="col-md-12 d-flex flex-column mt-5"
        v-if="(!isPublicJobIssuerBoardFetching && !getPublicJobIssuerBoard.length)"
      >
        <h4 class="text-center">
          No jobs found, Please add a Job
        </h4>
      </div>
      <div
        id="job-board-body"
        class="job-board-list col-md-4"
        v-if="
          !(!isPublicJobIssuerBoardFetching && !getPublicJobIssuerBoard.length)
        "
      >
        <div>
          <h6 v-if="false" class="count">
            Total Jobs: 0<span class="pl-3"></span> Search Results: 0
          </h6>
        </div>
        <job-card
          :key="index"
          :index="index"
          v-for="(job, index) in getPublicJobIssuerBoard"
          :item="job"
          :class="
            getSelectedPublicJobIssuerBoard &&
            getSelectedPublicJobIssuerBoard.job_id == job.job_id
              ? 'highlight'
              : 'disabled'
          "
          @click.native="jobCardSelectedCallback(job)"
        ></job-card>
        <div v-if="noMoreDataFromPublicJobIssuerBoard" class="no-more-data">
          <p v-if="getPublicJobIssuerBoard.length > 0">No More Data!</p>
        </div>
        <div
          v-if="!noMoreDataFromPublicJobIssuerBoard"
          class="
            d-flex
            justify-content-around
            align-items-center
            has-loading-overlay
          "
          style="height: 200px"
        >
          <LoadingOverlay v-if="isPublicJobIssuerBoardFetching" />
        </div>
      </div>
      <div
        class="job-board-detail col-md-8"
        v-if="
          getPublicJobIssuerBoard.length > 0 || isPublicJobIssuerBoardFetching
        "
      >
        <job-detail :getSelectedJobBoard="getSelectedPublicJobIssuerBoard">
        </job-detail>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import jobCard from "@/pages/DemoDOH/jobCard";
import publicJobDetail from "@/pages/JobIssuer/publicJobDetail";
import router from "@/router/index";
import { LOGIN_URL, getScope, Role } from "@/helpers/helper";

export default {
  components: {
    "job-card": jobCard,
    "job-detail": publicJobDetail,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      jobId: "",
      urlFullPath: "",
    };
  },
  mounted() {
    this.urlFullPath = this.$route.fullPath;
    this.publicJobIssuerBoardAction();
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
    let ele;
    if (!isMobile) {
      ele = document.getElementById("job-board-body");
    } else {
      if (this.urlFullPath.includes("/public")) {
        ele = document.getElementById("c-body-app");
      } else {
        ele = document.getElementById("c-body-app");
      }
    }
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    let ele;
    if (!this.isMobile) {
      ele = document.getElementById("job-board-body");
    } else {
      if (this.urlFullPath.includes("/public")) {
        ele = document.getElementById("app");
      } else {
        ele = document.getElementById("c-body-app");
      }
    }
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  methods: {
    ...mapActions([
      "showToast",
      "publicJobIssuerBoardAction",
      "selectedPublicJobIssuerBoard",
    ]),
    navigateToAddPublicJob() {
      router.push("/add/public/job");
    },
    jobCardSelectedCallback({ job_id, job_uuid }) {
      setTimeout(() => {
        const e = document.getElementsByClassName("loader");
        for (let i in e) {
          if (e.hasOwnProperty(i)) {
            e[i].style.borderTop = " 1rem solid #085D66E6";
            e[i].style.borderBottom = "1rem solid #085D66E6";
          }
        }
      }, 10);
      if (this.isMobile) {
        // this.$router.push({ path: `/doh/public/job-board-detail/${job_id}` });
      } else {
        let path = `${this.$route.path}?job_uuid=${job_uuid}`;
        this.$router
          .push({
            path,
          })
          .catch(() => {});
        this.selectedPublicJobIssuerBoard(parseInt(this.$route.query.job_uuid));
      }
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromPublicJobIssuerBoard || this.isPublicJobIssuerBoardFetching) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 1
      ) {
        this.publicJobIssuerBoardAction({
          pagination: true
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "isPublicJobIssuerBoardFetching",
      "getPublicJobIssuerBoard",
      "getSelectedPublicJobIssuerBoard",
      "noMoreDataFromPublicJobIssuerBoard",
    ]),
  },
  updated() {
    let { job_id } = this.$route.query;
    const jobId = parseInt(job_id) | 0;
    if (jobId !== this.jobId) {
      this.selectedPublicJobIssuerBoard(parseInt(jobId));
    }
    this.jobId = jobId;
  },
};
</script>